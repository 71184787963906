import { Box, Button, TextField, Typography, IconButton, InputAdornment, useTheme} from "@mui/material";
import { useState } from "react";
import { tokens } from "../../theme";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginPage = ({ onLogin }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
console.log(theme,"jitu")
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(email, password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor={colors.primary.main}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        maxWidth="400px"
        p={3}
        border={`1px solid ${colors.greenAccent[500]}`}
        borderRadius="8px"
        bgcolor={colors.primary[400]}
        mt={8}
      >
        <Box position="absolute" top="20px" left="20px">
         {theme.palette.mode==="light"? <img src="assets/logo.png" alt="Logo" width="200px" />:<img src="assets/logo_white.png" alt="Logo" width="200px" />}
        </Box>
        <Typography variant="h2" color={colors.greenAccent[500]} mb="20px">
          Login to Dashboard
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            style: { color: colors.grey[100] }
          }}
          InputProps={{
            style: { color: colors.grey[100], borderColor: colors.greenAccent[500] }
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
          variant="outlined"
          fontweight='bold'
          InputLabelProps={{
            style: { color: colors.grey[100] }
          }}
          InputProps={{
            style: { color: colors.grey[100], borderColor: colors.greenAccent[500] },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: colors.grey[100] }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {/* <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel style={{ color: colors.grey[100] }}>Role</InputLabel>
          <Select
            value={role}
            onChange={handleRoleChange}
            label="Role"
            style={{ color: colors.grey[100], borderColor: colors.greenAccent[500] }}
            inputProps={{
              style: { color: colors.grey[100], borderColor: colors.greenAccent[500] }
            }}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="viewer">Viewer</MenuItem>
          </Select>
        </FormControl> */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3, backgroundColor: colors.greenAccent[500], '&:hover': { backgroundColor: colors.greenAccent[700] } }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
