import { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Query from "./scenes/query";
import Users from "./scenes/Users";
import LoginPage from "./scenes/login";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import axios from 'axios';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check localStorage for authentication status
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
    setLoading(false); // Set loading to false after check
  }, []);

  const handleLogin = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        email: email,
        password: password,
      }, { withCredentials: true });

      if (response.status === 200) {
        const userRole = response.data.role;
        console.log(userRole, "jitu");
        setIsAuthenticated(true);
        
        localStorage.setItem("userRole", "admin");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem('isAuthenticated', 'true');
        navigate("/");
      } else {
        alert("Invalid credentials or role");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Invalid credentials or role");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("userRole");
    localStorage.removeItem('isAuthenticated');
    navigate("/login");
  };

  const userRole = localStorage.getItem('userRole');

  if (loading) {
    // Render a loading indicator or null while checking authentication
    return null;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && location.pathname !== "/login" && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {isAuthenticated && location.pathname !== "/login" && <Topbar setIsSidebar={setIsSidebar} onLogout={handleLogout} />}
            <Routes>
              <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
              <Route path="/" element={isAuthenticated ? <Dashboard userRole={userRole} /> : <LoginPage onLogin={handleLogin} />} />
              <Route path="/query" element={isAuthenticated ? <Query userRole={userRole} /> : <LoginPage onLogin={handleLogin} />} />
              <Route path="/users" element={isAuthenticated ? <Users userRole={userRole} /> : <LoginPage onLogin={handleLogin} />} />
              <Route path="/geography" element={isAuthenticated ? <Geography userRole={userRole} /> : <LoginPage onLogin={handleLogin} />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
