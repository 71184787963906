import { Box, useTheme, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Tooltip, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { tokens } from "../../theme";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("http://35.154.208.29:5000/api/summary/devices/?page=1&limit=10");
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        setUsers(data); // Assuming the API response has a structure you can use directly
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <Box m="20px">
      {/* <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Header title="Users" subtitle="All users and their device information" />
        </Grid>
      </Grid>
      <h1>user length: {users.Fullname}</h1> */}
      {/* {users.length > 0 ? (
        users.map((user, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Full Name: {user.Fullname} | Mobile Number: {user.mobile}
              </Typography>
              <Tooltip title="Click to view details" placement="top">
                <IconButton>
                  <InfoOutlinedIcon sx={{ color: colors.greenAccent[600] }} />
                </IconButton>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <strong>Device Count:</strong> {user.devices.length}<br />
                {user.devices.map((device, idx) => (
                  <Box key={idx} mb={2}>
                    <Typography>
                      <strong>Address:</strong> {device.address}<br />
                      <strong>Type:</strong> {device.type}
                    </Typography>
                  </Box>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography>No users found.</Typography>
      )} */}
    </Box>
  );
};

export default Users;
