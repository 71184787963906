import { Box, IconButton, Typography, useTheme, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonIcon from '@mui/icons-material/Person';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CallEndIcon from '@mui/icons-material/CallEnd';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Header from "../../components/Header";
import GeographyChart from "../../components/GeographyChart";
import StatBox from "../../components/StatBox";
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [authors, setAuthors] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token'); // Assuming you have stored the token in localStorage after login

    const fetchData = async () => {
      try {
        const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/summary`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (result.status === 403) {
          alert('Your session has expired. Please log in again.');
          localStorage.removeItem('token');
          localStorage.setItem('isAuthenticated', false);
          navigate('/login');
          return;
        }

        const jsonResult = await result.json();
        setAuthors(jsonResult);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Set up interval to fetch data every 30 seconds
    const intervalId = setInterval(fetchData, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);

  }, []);

  const tooltipTexts = {
    totalInstalledDevices: "Total number of AC units installed.",
    appDownloads: "Total number of app downloads from Playstore",
    totalUsers: "Total user sign-ins",
    activeUsers: "Count of the total Order Created",
    totalActiveDevices: "Total number of active devices.",
    totalServiceCalls: "Total number of Queris recieved on our app",
    activeServiceCalls: "Count of Unresolved Queries",
    totalCO2Saved: "Total CO2 saved by using our Circolife AC's."
  };

  const statBoxes = [
    {
      title: authors.Total_Installed_Device,
      subtitle: "Total installed ACs",
      icon: <FileDownloadDoneIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.totalInstalledDevices
    },
    {
      title: authors.Total_Users,
      subtitle: "App downloads",
      icon: <DownloadOutlinedIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.appDownloads
    },
    {
      title: authors.Total_Users,
      subtitle: "Total Users",
      icon: <PersonIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.totalUsers
    },
    {
      title: authors.Active_users,
      subtitle: "Active Users",
      icon: <PersonIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.activeUsers
    },
    {
      title: authors.uniqueDeviceCount,
      subtitle: "Total Active devices",
      icon: <PersonIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.totalActiveDevices
    },
    {
      title: authors.Total_service_Call,
      subtitle: "Total Service calls",
      icon: <CallEndIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.totalServiceCalls
    },
    {
      title: authors.Active_service_call,
      subtitle: "Active Service calls",
      icon: <AddIcCallIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.activeServiceCalls
    },
    {
      title: (authors.TotalPower * 0.12 *0.001).toFixed(2),
      subtitle: "Total CO2 Saved in Kg",
      icon: <EnergySavingsLeafIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />,
      tooltip: tooltipTexts.totalCO2Saved
    }
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your Summary dashboard" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {statBoxes.map((box, index) => (
          <Box
            key={index}
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <StatBox
              title={box.title}
              subtitle={box.subtitle}
              icon={box.icon}
            />
            <Tooltip title={box.tooltip} placement="top">
              <IconButton
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: colors.greenAccent[600]
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          padding="100px"
          paddingBottom='0px'
          paddingTop='30px'
          justifySelf='center'
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Installed AC Units Map
          </Typography>
          <Box height="500px">
            <GeographyChart />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
