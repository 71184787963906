


const GeographyChart = () => {
  return (
    <div>
      
      <iframe src="https://www.google.com/maps/d/embed?mid=1fiNbg3OjkNITzqtgHJiU_ElL9Tulgck&ehbc=2E312F" 
          width="600"
          height="500"
          style={{ border: 0 }}
          allowfullscreen=""
          // loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  );
};
export default GeographyChart;