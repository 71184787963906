import { Box, useTheme, FormControl, InputLabel, Select, MenuItem, Typography, Grid, Button, Divider, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { useNavigate } from 'react-router-dom';

const Query = ({ userRole }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [queries, setQueries] = useState([]);
  const [filterStatus, setFilterStatus] = useState("both");
  const [filterIssues, setFilterIssues] = useState("");
  const [notes, setNotes] = useState({}); // State to store notes for each query
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchData = async () => {
      try {
        const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/summary/query`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (result.status === 403) {
          alert('Your session has expired. Please log in again.');
          localStorage.removeItem('token');
          localStorage.setItem('isAuthenticated', false);
          navigate('/login');
          return;
        }

        const jsonResult = await result.json();
        setQueries(jsonResult);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleIssueChange = (event) => {
    setFilterIssues(event.target.value);
  };

  const handleNoteChange = (id, event) => {
    setNotes({ ...notes, [id]: event.target.value });
  };

  const handleStatusChange = async (id) => {
    const token = localStorage.getItem('token');
    const note = notes[id] || '';

    if (note === '') {
      alert("Please fill in the resolve note.");
      
      return;
    }
   
    try {
      const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/summary/queryresolved/?id=${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          resolvenote: notes[id] || ''
        })
      });

      if (result.status === 403) {
        alert('Your session has expired. Please log in again.');
        localStorage.removeItem('token');
        localStorage.setItem('isAuthenticated', false);
        navigate('/login');
        return;
      }

      const refreshedResult = await fetch(`${process.env.REACT_APP_API_BASE_URL}/summary/query`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const jsonResult = await refreshedResult.json();
      setQueries(jsonResult);
    } catch (error) {
      console.error('Error updating query status:', error);
    }
  };

  const issueCounts = queries.reduce((acc, query) => {
    acc[query.subject] = (acc[query.subject] || 0) + 1;
    return acc;
  }, {});

  const issueOptions = [
    "AC Not Cooling",
    "AC not getting On",
    "Getting Error Code on AC",
    "Water Dripping from AC",
    "AC is not getting connected to App",
    "App related Issues",
    "Request for Routine service",
    "AC is making noise",
    "Remote of AC not working",
    "Outdoor Unit of AC not working",
    "Ice formation on AC",
    "Others"
  ];

  const filteredQueries = queries.filter(query => {
    if (filterStatus !== "both" && query.status.toString() !== filterStatus) {
      return false;
    }
    if (filterIssues && query.subject !== filterIssues) {
      return false;
    }
    return true;
  });

  const totalIssuesCount = queries.length;

  return (
    <Box m="20px">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Header title="Queries" subtitle="All Queries are listed here" />
        </Grid>
        
        <Grid item>
          <FormControl variant="outlined" size="small">
            <InputLabel>Status</InputLabel>
            <Select value={filterStatus} onChange={handleFilterChange} label="Status">
              <MenuItem value="both">All Queries</MenuItem>
              <MenuItem value="true">Pending Queries</MenuItem>
              <MenuItem value="false">Resolved Queries</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Issues</InputLabel>
            <Select value={filterIssues} onChange={handleIssueChange} label="Issues">
              <MenuItem value="">All Issues ({totalIssuesCount})</MenuItem>
              {issueOptions.map(issue => (
                <MenuItem key={issue} value={issue}>
                  {issue} ({issueCounts[issue] || 0})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {filteredQueries.map((query, index) => (
        <Accordion key={query._id} defaultExpanded={index === 0} style={{ marginBottom: '10px' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colors.greenAccent[500]} variant="h5">
              {query.subject}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item xs={10}>
                <Typography>
                  Customer Name: {query.contactperson}<br />
                  Date Posted: {new Date(query.TimeStamp).toLocaleDateString()}<br />
                </Typography>
                <Divider style={{ margin: '10px 0' }} />
                <Typography>
                  Contact Email: {query.contactemail}<br />
                  Contact No.: {query.contactnumber}<br />
                </Typography>
                <Divider style={{ margin: '10px 0' }} />
                <Typography>
                  <span style={{ fontSize: 'medium' }}>
                    Summary: {query.summery} <br/>
                    Image Link: {query.image}
                  </span><br />
                  Status: <span style={{ color: query.status ? 'red' : 'green' }}>{query.status ? "Pending" : "Resolved"}</span>
                </Typography>
                {userRole === 'admin' && query.status && (
                  <TextField
                    label="Resolve Note"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={notes[query._id] || ''}
                    onChange={(event) => handleNoteChange(query._id, event)}
                    style={{ marginTop: '10px' }}
                  />
                )}
              </Grid>
              {userRole === 'admin' && query.status && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleStatusChange(query._id)}
                  >
                    Mark as Resolved
                  </Button>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Query;
